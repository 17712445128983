'use strict';

/* Image Scroller */
function ssImgScrollerInit() {

    document.addEventListener('DOMContentLoaded', () => {
        const ssImgScrollers = document.querySelectorAll('.st-img-scroller');

        ssImgScrollers.forEach((ssImgScroller) => {

            ssImgScroller.addEventListener('mouseenter', () => {
                const speed = ssImgScroller.getAttribute('data-speed');
                const containerHeight = ssImgScroller.getAttribute('data-height');
                const image = ssImgScroller.querySelector('.image-holder img');
                const imgHeight = image.clientHeight;
                const topMargin = (imgHeight - containerHeight);

                image.style.top = `-${topMargin}px`;
                image.style.animationDuration = `${speed}ms`;
            });

            ssImgScroller.addEventListener('mouseleave', () => {
                const speed = ssImgScroller.getAttribute('data-speed');
                const image = ssImgScroller.querySelector('.image-holder img');
                image.style.top = '0px';
                image.style.animationDuration = `${speed}ms`;
            });

        });
    });

}
ssImgScrollerInit();

/* Message Box - Callouts */
function ssMessageBoxInit() {

    document.addEventListener('DOMContentLoaded', () => {
        const ssCallouts = document.querySelectorAll('.ss-callout');

        ssCallouts.forEach((ssCallout) => {
            const colHeight = ssCallout.querySelector('.callout-body').offsetHeight;
            const infoHeight = ssCallout.querySelector('.btn-callout').offsetHeight;
            const btnCallout = ssCallout.querySelector('.btn-callout');

            // Calculate and set the margin-top to center the button
            btnCallout.style.marginTop = `${(colHeight / 2) - (infoHeight / 2)}px`;
        });
    });

}
ssMessageBoxInit();

/* Tooltip */
if (typeof tippy !== 'undefined') {
    tippy('.ss-tooltip', {
        maxWidth: 250,
        allowHTML: true,
    });
}

/* Popover */
if (typeof tippy !== 'undefined') {
    tippy('.ss-popover', {
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true,
        maxWidth: 250,
        trigger: 'click',
        theme: 'light-border',
        hideOnClick: true,
    });
}

/* Modal */
function ssModalInit() {

    const modalsWrap = document.querySelectorAll('.ss-modal-wrap');
    modalsWrap.forEach((wrap) => {

        const modal = wrap.querySelector('.ss-modal');
        const trigger = wrap.querySelector('.ss-trigger-modal');
        const closeButton = wrap.querySelector('.close-button');
        const allButtons = document.querySelectorAll('.ss-trigger-modal');
        const allIconBoxes = document.querySelectorAll('.ss-icon-box-wrap');

        const toggleModal = () => {
            modal.classList.toggle('show-modal');

            allButtons.forEach((button) => {
                button.classList.toggle('hide-modal-button');
            });

            allIconBoxes.forEach((button) => {
                button.classList.toggle('ss-hide');
            });
        };

        const windowOnClick = (event) => {
            if (event.target === modal) {
                toggleModal();
            }
        };

        if (trigger !== null) {
            trigger.addEventListener('click', toggleModal);
            closeButton.addEventListener('click', toggleModal);
            window.addEventListener('click', windowOnClick);
        }

    });

}
ssModalInit();

/* Tabs */
function ssTbsInit() {

    const tabsWrap = document.querySelectorAll('.ss-tabs-wrap');
    tabsWrap.forEach((wrap) => {

        const tabs = wrap.querySelectorAll('.ss-tabs-holder li');
        const sections = wrap.querySelectorAll('.ss-tabs-holder .ss-tab-content');

        const removeActiveTab = () => {
            tabs.forEach((tab) => {
                tab.classList.remove('is-active');
            });
            sections.forEach((section) => {
                section.classList.remove('is-active');
            });
        };

        const addActiveTab = (tab) => {
            tab.classList.add('is-active');
            const href = tab.querySelector('a').getAttribute('href');
            const matchingSection = document.querySelector(href);
            matchingSection.classList.add('is-active');
        };

        tabs.forEach((tab) => {
            tab.addEventListener('click', (e) => {
                e.preventDefault();
                removeActiveTab();
                addActiveTab(tab);
            });
        });

        // create the container ul
        const ul = document.createElement('ul');
        // get all lis
        const lis = wrap.querySelectorAll('.ss-tabs-holder li');
        // get the body element
        const nav = wrap.querySelector('.ss-tabs-nav-holder');
        // apply class to container ul
        ul.setAttribute('class', `ss-tabs-nav ${wrap.id}`);

        // find out all those lis having class ss-tab
        for (let i = 0; i < lis.length; i++) {

            if (lis[i].classList.contains('ss-tab')) {
                // put the lis having class ss-tab inside container ul
                ul.appendChild(lis[i]);
            }
        }

        // finally append the container ul to nav
        nav.appendChild(ul);

    });

}
ssTbsInit();

/* Accordion */
function ssAccordionInit() {

    const acc = document.getElementsByClassName('ss-accordion');
    let i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {

            const panel = this.nextElementSibling;
            const parent = panel.parentNode.parentNode;
            const buttons = parent.querySelectorAll('.ss-accordion');
            const sections = parent.querySelectorAll('.ss-acc-panel');

            buttons.forEach((btn) => {
                btn.classList.remove('active');
            });

            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                sections.forEach((section) => {
                    section.style.maxHeight = null;
                });

                this.classList.toggle('active');
                panel.style.maxHeight = `${panel.scrollHeight}px`;
            }
        });
    }

}
ssAccordionInit();

/* Toggle */
function ssToggleInit() {

    const tog = document.getElementsByClassName('ss-toggle');
    let i;

    for (i = 0; i < tog.length; i++) {
        tog[i].addEventListener('click', function () {
            this.classList.toggle('active');
            const panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = `${panel.scrollHeight}px`;
            }
        });
    }

}
ssToggleInit();

/* Tweet */
function ssTwitterInit() {

    window.twttr = (function (d, s, id) {
        let js,
            fjs = d.getElementsByTagName(s)[0],
            t = window.twttr || {};
        if (d.getElementById(id)) return t;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://platform.twitter.com/widgets.js';
        fjs.parentNode.insertBefore(js, fjs);

        t._e = [];
        t.ready = function (f) {
            t._e.push(f);
        };

        return t;
    }(document, 'script', 'twitter-wjs'));

}
ssTwitterInit();

/* Mediaelement */
function ssMediaelementInit() {

    if (typeof mejs !== 'undefined') {

        const settings = window._wpmejsSettings || {};
        settings.features = settings.features || mejs.MepDefaults.features;
        settings.features.push('exampleclass');

        MediaElementPlayer.prototype.buildexampleclass = function (player) {
            player.container.addClass('ss-mejs-container');
        };

    }

}
ssMediaelementInit();

/* Contact Form validation */
function ssContactFormValidation() {

    const sendButtons = document.querySelectorAll('.form-button');

    if (sendButtons) {
        Object.keys(sendButtons).forEach((key) => {
            sendButtons[key].onclick = (event) => {
                const { target } = event,
                    formSubscribe = target.closest('.form-message-box'),
                    formSubscribeInputs = formSubscribe.querySelectorAll('input');

                Object.keys(formSubscribeInputs).forEach((i) => {
                    if (formSubscribeInputs[i].value === '') {
                        formSubscribeInputs[i].closest('.form-message-box').classList.add('error');
                    }
                });
            };
        });
    }

}
ssContactFormValidation();

/**
* Handles the contact form ajax send.
* Depends on Google reCaptcha.
*
*/
(function ($) {
    $('.ss-contact-form').each(function () {
        $(this).on('submit', function (event) {

            // Prevent default
            event.preventDefault();

            // Variables
            const form = $(this);
            const formData = $(form).serialize();
            const captcha = form.find('.google-inline-captcha');
            const loader = form.find('.form-loader');
            const messageBox = form.find('.form-message-box');

            loader.addClass('show');

            // first check if captcha is there/API keys are provided in theme options
            if (captcha.length === 1) {

                captcha.find('.btn-close').on('click', function () {
                    $(this).parents(captcha).removeClass('show form-success');
                });

                $.ajax({
                    url: SS_VARS.ajax_url,
                    type: 'POST',
                    data: {
                        action: 'send_message',
                        data: formData,
                    },
                    dataType: 'json',
                }).done((data) => {

                    loader.removeClass('show');

                    captcha.addClass('show').removeClass('form-success');

                    // console.log(data);

                    if (data.google_response.success === true) {

                        $(captcha).removeClass('show').addClass('form-success');
                        $(captcha).find('.form-messages').html(data.message);

                        form.find('input[name="name"]').val('');
                        form.find('input[name="email"]').val('');
                        form.find('textarea[name="content"]').val('');

                    } else {

                        $(captcha).find('.form-messages').html(data.message);

                    }

                    $(messageBox).removeClass('error');

                }).fail(() => {
                    // If error
                    // console.log(`error is:: ${error}`);
                });

            }

        });
    });
}(jQuery));

/* Fancybox */
if (typeof Fancybox === 'function') {
    Fancybox.bind('[data-fancybox]', {
        // custom code here
    });
}

/* To Top */
function ssToTopInit() {
    const toTop = document.querySelector('.to-top');

    if (toTop !== null) {
        toTop.addEventListener('click', (event) => {
            event.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    }
}
ssToTopInit();

/* Post Carousel and Testimonials */
document.addEventListener('DOMContentLoaded', () => {
    if (typeof Swiper !== 'undefined') {
        const ssSwiper = new Swiper('.ss-swiper-container', {
            slidesPerView: 4,
            spaceBetween: 0,
            grabCursor: true,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                550: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
            },
        });

        const ssCarousel = new Swiper('.ss-carousel-container', {
            slidesPerView: 4,
            spaceBetween: 18,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                550: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 14,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 18,
                },
            },
        });

        const ssTestimonials = new Swiper('.ss-testimonials-container', {
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
});

/* Parallax */
document.addEventListener('DOMContentLoaded', () => {
    const ssWindowEl = window;
    let windowHeight = ssWindowEl.innerHeight;

    ssWindowEl.addEventListener('resize', () => {
        windowHeight = ssWindowEl.innerHeight;
    });

    function ssparallax(element, xpos, speedFactor, outerHeight) {
        const elementEl = document.querySelector('.ss-parallax-section');
        let firstTop;

        // Get the starting position of the element to apply parallax
        firstTop = ((elementEl.offsetTop) / 2) * 0.02;

        ssWindowEl.addEventListener('resize', () => {
            firstTop = ((elementEl.offsetTop) / 2) * 0.02;
        });

        ssWindowEl.addEventListener('load', () => {
            firstTop = ((elementEl.offsetTop) / 2) * 0.02;
        });

        const getHeight = function (el) {
            return el.offsetHeight;
        };

        // Setup defaults if arguments aren't specified
        if (arguments.length < 2 || xpos === null) xpos = '50%';
        if (arguments.length < 3 || speedFactor === null) speedFactor = 0.1;
        if (arguments.length < 4 || outerHeight === null) outerHeight = true;

        // Function to be called whenever the window is scrolled or resized
        function update() {
            const pos = ssWindowEl.scrollY;
            const elementTop = elementEl.offsetTop;
            const elementHeight = getHeight(elementEl);

            // Check if element is within the viewport
            if (elementTop + elementHeight < pos || elementTop > pos + windowHeight) {
                return;
            }

            elementEl.style.backgroundPosition = `${xpos} ${Math.round((firstTop - pos) * speedFactor)}px`;
        }

        ssWindowEl.addEventListener('scroll', update);
        ssWindowEl.addEventListener('resize', update);
        update();
    }

    // Disable parallax if touch device
    if (!(/(Android|iPod|iPhone|iPad|IEMobile|Opera Mini)/.test(navigator.userAgent))) {
        const ssParallaxSections = document.querySelectorAll('.ss-parallax-section');
        ssParallaxSections.forEach((section) => {
            const { id } = section;
            ssparallax(`#${id}`, '0', 0.2);
        });
    }
});

/* Animated */
function ssAnimatedInit() {

    // Handle all on-click animated elements
    const allClickAnimated = document.querySelectorAll('.click-animated');
    allClickAnimated.forEach((animatedel) => {
        const animation = animatedel.getAttribute('data-animation');

        const removeAnimation = () => {
            animatedel.classList.remove('animate__animated');
            animatedel.classList.remove(`animate__${animation}`);
        };

        const addAnimation = () => {
            animatedel.classList.add('animate__animated');
            animatedel.classList.add(`animate__${animation}`);
        };

        animatedel.addEventListener('click', () => {
            removeAnimation();
            setTimeout(addAnimation, 200);
        });
    });

    // Handle all on-hover animated elements
    const allHoverAnimated = document.querySelectorAll('.hover-animated');
    allHoverAnimated.forEach((animatedel) => {
        const animation = animatedel.getAttribute('data-animation');

        animatedel.addEventListener('mouseenter', () => {
            animatedel.classList.add('animate__animated');
            animatedel.classList.add(`animate__${animation}`);
        });

        animatedel.addEventListener('mouseleave', () => {
            animatedel.classList.remove('animate__animated');
            animatedel.classList.remove(`animate__${animation}`);
        });
    });

    // Handle all on-scroll animated elements

    /* Animated */
    // handle all on-scroll animated elements
    document.addEventListener('DOMContentLoaded', () => {
    // Check if element is scrolled into view
        function isScrolledIntoView(elem) {
            const docViewTop = window.scrollY;
            const docViewBottom = docViewTop + window.innerHeight;

            const elemTop = elem.offsetTop;
            const elemBottom = elemTop + elem.offsetHeight;

            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        }

        // If element is scrolled into view, fade it in
        window.addEventListener('scroll', () => {
            const scrollAnimatedElements = document.querySelectorAll('.scroll-animated');
            scrollAnimatedElements.forEach((element) => {
                const animation = element.getAttribute('data-animation');
                if (isScrolledIntoView(element)) {
                    element.classList.add('animate__animated');
                    element.classList.add(`animate__${animation}`);
                }
            });
        });
    });
}
ssAnimatedInit();
